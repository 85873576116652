.slider {
    /* The image used */
    background-image: url("https://i.pinimg.com/originals/0f/e2/55/0fe2554e1a10ad2c4c67aff2d2d4dbec.jpg");
    
    /* Set a specific height */
    min-height: 80vh;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -80px;

  }

  .s-texts{
    margin-top: 150px;
    margin-left: 50px;
 
  }

  


.p-slider{
  display: block;
  font-size: 20px;

}

  @media (max-width: 657px) {

         .s-texts{
          margin-left:10px;
         }

         .p-slider{
          display: none;
         }




  }