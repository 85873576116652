.nav-bar {
    grid-template-columns: auto auto auto;
    min-height: 6.25rem;

    z-index: 99;
  }
  
  .nav-bar-logo {
    grid-column: 1;
    justify-self: start;
    align-self: center;
    line-height: 0;
    margin-left: -128px;
    margin-top: -70px;
    
    width: 200px;
    
    
  }
  
  .nav-bar-logo img {
    max-width: 15.625rem;
    padding-left: 150px;
    padding-top: px;
    
    
  }
  
  .nav-bar-hamburger {
    grid-column: 2;
    display: none;
    cursor: pointer;
    color: #464040;
    transition: background-color 0.5s;
    margin-top: -30px;
  }
  
  .nav-bar-hamburger:hover {
    background-color: #ffffff;
  }
  
  .nav-bar-links {
    grid-column: 3;
    display: flex;
    justify-content: right;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    list-style-type: none;
    transition: left 0.3s;
    margin-top: -160px;
   
   
    
    
  }
  
  .nav-bar-links.expanded {
    left: 0;
  }
  
  .nav-bar-links li {
    display: flex;
    padding-left: 30px;
    padding-bottom: 1px;
    

    
  }
  
  .nav-bar-links a {
    display: flex;
    align-items: center;
    padding-top: 2.0rem;
    padding-left: 1rem;

    padding-bottom: 0rem;
    text-transform: uppercase;
    transition: background-color 0.5s;
    text-decoration-line: none;
    
    margin-top: 58px;
    margin-right: 30px;
    color: rgb(13, 12, 12);
    text-align: center;
  }
  
  
  .nav-bar-links a:hover, 
  .nav-bar-links a:focus {
   
   color: #888d8f;
    
    
  }
  
  @media only screen and (max-width: 992px) {
    .nav-bar {
      grid-template-columns: auto auto;
    }
  

    .nav-bar-links a {
    
    color: black;
    }
    .nav-bar-hamburger {
      width: 2.5rem;
      justify-self: end;
      display: inline-block;
      background: none;
      border: none;
     
    }
  
    .nav-bar-links {
      position: absolute;
      flex-direction: column;
      width: 50%;
      height: 100vh;
      top: 0;
      left: -50%;
      background-color: #FFFFFF;
      box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.2);
      z-index: 100;
      padding-top: 30px;
      
    }
  
    .nav-bar-links li {
      flex-direction: column;
    }

    .nav-bar-logo img{
        padding-left: 140px;
    }
  }