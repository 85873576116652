@import "./global.scss";

body{
    background-color: $body-color;
    color: $secondary-color;
    font-family: $main-font;
    font-size: $text-lg;
    font-weight: 500;
}


@mixin button($name, $size) {
    @if $name == primary{
        background-color: $primary-color;
        border: 2px solid $white;
        border-radius: 9999px;
        cursor: pointer;
        line-height: 0;
        transition: $transition-default;
        &:hover{
            background-color: $primary-hover;
            border: 2px solid $primary-text-color;
        }

        @if $size == sm{
            font-size: $text-md;
            padding: 4px 14px;
        };
        @if $size == md{
            font-size: $text-lg;
            padding: 10px 20px;
        };
        @if $size == lg{
            font-size: $text-xl;
            padding: 16px 26px;
        };
    };
    @if $name == secondary{
        background-color: $white;
        border: 2px solid $primary-color;
        border-radius: 9999px;
        cursor: pointer;
        line-height: 0;
        transition: $transition-default;
        
        &:hover{
            background-color: $primary-color;
            border: 2px solid $white;
            box-shadow: 0 8px 10px 0px rgb(0 0 0 / 10%)
        }

        
        @if $size == sm{
            font-size: $text-sm;
            padding: 5px 5px;
        };
        @if $size == md{
            font-size: $text-md;
            padding: 10px 10px;
        };
        @if $size == lg{
            font-size: $text-xl;
            padding: 16px 16px;
        };
    };

    @if $name == tertiary{
        background-color: $white;
        border: 2px solid $primary-color;
        border-radius: 9999px;
        cursor: pointer;
        line-height: 0;
        transition: $transition-default;
        
        &:hover{
            background-color: $primary-color;
            border: 2px solid $primary-text-color;
        }


    }
  }
  