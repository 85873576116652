
.s-container{
    
}
 .background-container {
    /* Set the background image URL */
    background-image: url('https://tse2.mm.bing.net/th?id=OIP.sRgEs7tjFaFPzSQgL98wYgHaDW&pid=Api&P=0&h=220');
    
    min-height: 250px;
    margin-top: -50px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }

.s-title h1{
    font-family: cursive;
    font-size: 60px;
    color: rgb(249, 249, 249);
    text-align: center;
    text-decoration-line: underline;
    margin-top: -130px;
}

.s-left{
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 100px;
}
.left1{
    width: 325px;
    background-color: beige;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
    margin: 20px;
}

.left1 img{
    width: 100%;
    height: auto;
}
.left1-conten{
    padding: 16px;
}
.left1-content h1{
    font-size: 28px;
    margin-bottom: 8px;
    color: rgb(37, 37, 143);
}
.left1-conten p{
    color: #666;
    font-size: 15px;
    line-height: 1.3;
 

}
@media (max-width: 657px) {
  

    .s-container{
        display: flex;
        flex-direction: column;

    }

    .left1{
        order:2;
       
    }

    .s-hr{
        width: 100%;
        margin-left: 0px;
    }
    
    .left1 img{
        width: 350px;
     }

     
}
