
.info{
    flex: 1;
  
}

.container{
    width:90%;
    margin: 0 auto;
    padding: 10px 20px;
}
.about{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.r-image{
    flex: 1;
    margin-right: 40px;
    overflow: hidden;
}

.r-image img{
    max-width: 100%;
    height: 500px;
    display: block;
    transition: 0.5s ease; 
    border-radius:10px;

}
.r-image img:hover img{
    transform: scale(1.2);
}

@media screen and (max-width : 768px){
    .container{
        padding: 0px;
    }
    .about{
        padding: 20px;
        flex-direction: column;
    }
    .r-image{
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .info p{
        padding: 0px;
        font-size: 16px;
    }
    .info .read-more{
        font-size: 16px;
    }
}