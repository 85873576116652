$primary-color: #F1BC56;
$primary-hover: #daa749;
$secondary-color: #696969;
$tertiary-color: #D9D9D9;

$primary-text-color: #4A291E;

$body-color: #FFFCF7;

$white: #FFFFFF;
$black: #000000;


// Font Family

$main-font: 'Roboto', sans-serif;
$title-font: 'Righteous', cursive;

// Font Sizes

$text-xs: 10px;
$text-sm: 12px;
$text-md: 14px;
$text-lg: 16px;
$text-xl: 18px;
$text-2xl: 20px;
$text-3xl: 22px;
$text-4xl: 24px;
$text-5xl: 26px;
$text-6xl: 28px;
$text-7xl: 36px;
$text-8xl: 44px;
$text-9xl: 60px;
$text-10xl: 68px;

// Container

$container-xs: 580px;
$container-sm: 1050px;
$container-md: 1290px;
$container-lg: 1399px;
$container-xl: 1490px;


// Transition

$transition-short: all 250ms ease;
$transition-default: all 350ms ease-in;
$transition-long: all 450ms ease;
