.about{
  margin-left: 10px;
  margin-right: 10px;
}
.a-left{
    padding-left: 60px;
    padding-top:70px;
}

.a-container{
    display: flex;
    flex-direction: row;
    margin-right: 40px;
}

.ss-title h1{
    font-family: cursive;
    font-size: 60px;
    color: rgb(249, 249, 249);
    text-align: center;
    
    margin-top: -130px;
}


.background-container {
    /* Set the background image URL */
    background-image: url('https://tse2.mm.bing.net/th?id=OIP.sRgEs7tjFaFPzSQgL98wYgHaDW&pid=Api&P=0&h=220');
    
    min-height: 250px;
     margin-top: -50px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }
.a-right h1{
    font-size: 35px;
    margin-top: 100px;
    padding-left: 300px;
    font-family: cursive;
    text-decoration-line: underline;
    color: rgb(19, 19, 72);
}

.a-right h2{
    color: black;
    font-size: 30px;
    font-weight: 1px;
    
    margin-top: 30px;
    padding-left: 30px;
    
}

.a-right h5{
    
    font-size: 20px;
    font-weight: 40;
    margin-top: 30px;
    padding-left: 30px;
    text-align: justify;
    color: black;
    
}

.t-aboutp{
    padding-left: 250px;
    padding-right: 250px;
    text-align: justify;
        font-size: 20px;
        font-weight: 40;
    
}


.a-Container {
   
    position: relative;
    display: flex;
    justify-content: space-evenly;
    
  }

 
  

  .righ h3{
   text-align: center;
   padding-top: 15px;
   
  }
  .righ h6{
    text-align: center;
    padding: 1px;
    color: #131E3A;
   }

  .righ img{
 
    border-radius: 8px !important;
  /* Optional: Add styling for individual images here */
  
  
  }



@media (max-width: 657px) {
     

     .a-Container{
        display: flex;
        flex-direction: column;
        margin-left: 70px;
        
     }

     
  .righ h3{
    margin-left: -50px;
  }
  .righ h6{
    margin-left: -50px;
  }


    .a-left{
      order: 2;
    
    }

    .t-aboutp{
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-align: justify;
        font-size: 20px;
    
    font-weight: 40;
       
        
    }

    .a-left img{
        width: 300px;
        height: 60px;
        margin-left: -80px;
    }

    .a-right{
        order:1
    }

    .a-right h1{
        margin-left: -190px;
        margin-top: 20px;
    }

    .a-container{
        display: flex;
        flex-direction: column;
    }
}
