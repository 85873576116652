

body {
    font-family: Arial, sans-serif;
    
  }
  

   
     .background-container {
    /* Set the background image URL */
    background-image: url('https://tse2.mm.bing.net/th?id=OIP.sRgEs7tjFaFPzSQgL98wYgHaDW&pid=Api&P=0&h=220');
    
    min-height: 250px;
     margin-top: -50px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }
  

  .m-all{
    justify-content: center;
    display: flex;
    
    
  }
  .m-tab {
    display: inline-block;
    cursor: pointer;
    padding: 6px 40px;
    text-align: center;
    padding-top: 13px;
    border-radius: 10px;
    
      
     margin-right: 50px;
    border: 1px solid #ccc;
    background-color: rgb(229, 218, 218);
    transition: background-color 0.3s ease;
  }

  .menu-title h1{
    font-family: cursive;
    font-size: 70px;
    
    text-align: center;
    color: white;
    margin-top: -100px;
  }
  
  .m-tab:hover {
    background-color: rgb(238, 213, 191);;
  }
  
.m-flex{
    display: flex;
    flex-direction: row;
}
.m-tabcontent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    
  }
  
  m-img {
    max-width: 100%;
    
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  

  .m-img img{
    display: flex;
    flex-direction: row;
    height: 350px;
    width: 400px;
    border-radius: 20px;
  }

  .m-text{
    margin-left: 50px;
  }

  .m-text li{
    margin-top: 35px;
    list-style-type: disc; 
    font-size: 25px;
  }

  .m-tab.active {
    
    background-color: #007bff;
    color: #fff;
  }

  @media only screen and (max-width: 992px) {
     .m-text{
      order: 1;
      margin-left: 150px;
      margin-top: -10px;
     }
     .m-all{
      margin-left: 20px;

     }
     
     .m-row1{
      display: flex;
      flex-direction: column;
     }
     .m-row2{
      display: flex;
      flex-direction: column;
     }
     .m-tab{
      width: 50px;
      margin-bottom: 10px;
     }

     .m-tab.active {
      /* Your active tab styles */
      background-color: #007bff;
      color: #fff;
      /* Add any other styles for the active state */
    }
     .m-img{
      order: 2;
      
     }

     .m-img img{
      width: 340px;
      margin-left: 6px;
     }

     .m-flex{
      display: flex;
      flex-direction: column;
     }
     
     
     
     
     
     

  }