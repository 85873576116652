.catering-container {
    position: relative;
  }
  
  .catering-text {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    color: black;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .decription {
    margin-top: 20px;
  }
  