.c-container img{
  width: 100%;
  height: 300px;
  position: relative;
text-align: center;

}


.c-container h1{
  padding-top: 80px;
  font-size: 28px;
  text-align: center;
  padding-bottom: 30px;
  
}

.c-container h5{
  text-align: center;
  max-width: 800px;
  margin: 100 auto;
  padding-left: 280px;
  padding-right: 250px;
  padding-bottom: 30px;
  font-size: 20px;
  word-spacing: 0.3rem;
  font-size: 20px;
  color: black;
  font-weight: 40;
}
.image {
width: 100%;
height: auto;
}

.centered-text {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
color: black;
font-size: 204px;
font-weight: bold;
}

.follow h2{
  text-align: center;
  font-size: 20px;
  color:rgb(19, 19, 72);
  
}

.follow{
align-items: center;
}


.c-social {
display: flex;
 justify-content: center;

}


.c-social button {
margin: 0 3px; /* Add margin between the buttons */
border-radius: 8px
}
.c-social img{
  width: 28px;
  height: 28px;
  
}


.c-title h1{
  font-family: cursive;
  font-size: 40px;
  text-decoration-line: underline;
  text-align: center;
  color:rgb(19, 19, 72);
}
.c-contact{
  
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
 
  
}

.cc-container{
  color:rgb(19, 19, 72);
  width: 250px;
  height: 200px;
  background-color: rgb(240, 228, 212);
  text-align: center;
  font-family: cursive;
  font-size: 25px;
  border-radius: 10px;
 
  
}



.c-name{
display: flex;
flex-direction: row;
justify-content: center;

}


.c-name input{
width: 380px;
height: 40px;

}

.c-subject input{
width: 800px;
height: 40px;
}

.c-message textarea{
width: 800px;
height: 100px;
}

.c-submit button{
width: 180px;
height: 45px;
border-radius: 10px;
}

.c-hr{
  width: "70%" ;
  margin-left:"230px"
}

.form-container {
width: 100%; /* Adjust as needed */
margin: 0 auto; /* This centers the container horizontally */
text-align: center; /* Centers the form elements inside */
}

.map-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 80px; /* 16:9 aspect ratio (height:width) */
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}





@media only screen and (max-width: 657px) {

  .c-container h5{
    text-align: center;
    max-width: 95%;
    padding:  10px;
    
  }


.t-map iframe{
  width: 350px;
  margin-left: 30px;
}


  .c-contact{
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

 

  .cc-container{
    margin-left: 40px;
    margin-bottom: 30px;

    }
    .c-hr{
      margin-left: 50px;
      width: 70%;
    }
  
 .c-name{
  display: flex;
  flex-direction: column;

 }



 

 .c-name input{
  width: 300px;
  margin-left: 30px;
 }

 .c-message textArea{
    width: 350px;
 }




  .c-subject input{
    width: 300px;
  }
}