// Import necessary styles
@import "../../assets/styles/global.scss";
@import "../../assets/styles/main.scss";

// Apply styles to header
header {
  padding: 28px 0;
  position: relative;
  background-color:#CFA670;
  transition: $transition-default;
  z-index: 50;
  height: 35px;

  // Rest of your existing styles
  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: $container-md;

    // Rest of your existing styles
    .logo {
      margin-top: -10px;
      width: 220px;
      img {
        cursor: pointer;
        width: 220px;
      }
    }
    .menu {
      align-items: center;
      display: flex;
      gap: 44px;

      nav ul {
        display: flex;
        gap: 40px;
        @media (max-width: $container-sm) {
          display: none;
        }
        li a {
          color: $secondary-color;
          cursor: pointer;
          font-size: $text-xl;
          transition: $transition-default;
          &:hover {
            color: $black;
          }
        }
      }
      .buttons {
        display: flex;
        gap: 20px;

        .button-primary {
          @include button(primary, lg);

          @media (max-width: $container-xs) {
            display: none;
          }
        }
        .button-secondary {
          @include button(secondary, lg);
          @media (max-width: $container-xs) {
            display: none;
          }
        }
        .button-menu {
          @include button(secondary, lg);
          @media (min-width: $container-xs) {
            display: none;
          }
          img {
            height: 20px;
            width: 18px;
          }
        }
      }
    }
  }

  // Add parallax effect to the background
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
     // Adjust the background color and opacity
    z-index: -1;
    transition: $transition-default;
  }
}

// Active header styles
.active-header {
  // Your existing styles for the active header
  background-color: $white;
  box-shadow: 0px 8px 8px rgb(0 0 0 / 5%);
  padding: 15px 0;
  .logo {
    margin-top: 0 !important;
  }

  // Additional styles for the parallax effect on the active header
  &::before {
    background: rgba(255, 255, 255, 0); // Adjust the background color and opacity
  }
}
