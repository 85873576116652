.tt-map iframe{
    width:350px;
    height: 200px;
    padding-left: 30px;
}




@media only screen and (max-width: 657px) {

.tt-map iframe{
    width: 250px;
    margin-left: -250px;
}

}