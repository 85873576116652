.description12 h1{
    text-align: justify;
    max-width: 1200px;
   
}
.description12{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


@media only screen and (max-width: 664px) {


    .ibuttons{
          display: flex;
          align-items: center;
          justify-content: center;
    }
    
   .irctcimage{


    display: flex;
    justify-content: center;
    align-items: center;
   


   }

   .irctcimage img{ 
    width: 650px;
    border-radius: 25px;
   }


    .description12 h1{
        text-align: justify;
        max-width: 360px;
       
    }

    .ibuttons button{
        border-radius: 20px;
        background-color: rgb(238, 171, 45);
        border: none;
        height: 50px;
        width: 180px;

    }



}