.book-container {
    background-color: #ffeeba;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    margin-top: 50px;
  }
  
  .book-heading {
    font-size: 24px;
    font-weight: bold;
    color: #856404;
    margin-bottom: 10px;
  }
  
  .book-paragraph {
    font-size: 16px;
    color: #856404;
    margin-bottom: 20px;
  }
  
  .book-now-button {
    background-color: #ffc107;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .book-now-button:hover {
    background-color: #ffca28;
  }
  