@import "../../../assets/styles/main.scss";
@import "../../../assets/styles/global.scss";

.slider {
  margin-top: 6rem;
  position: relative;

  @media (max-width: $container-md) {
    margin-top: 2rem;
    padding: 0 20px;
  }
  .container {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: $container-md;

    @media (max-width: $container-md) {
      flex-direction: column;
      justify-content: center;
    }
    .s-texts {
      max-width: 500px;
      @media (max-width: $container-md) {
        align-items: center;
        display: flex;
        font-size: 40px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        
        text-align: center;
        width: 100%;
      }
      h1 {
        color: $black;
        font-family: $title-font;
        font-size: $text-10xl;
        line-height: 1.2;
        @media (max-width: $container-md) {
          font-size: $text-8xl;
        }
        span {
          @media (max-width: $container-md) {
            display: none;
          }
        }
      }
      p {
        font-weight: 400;
        line-height: 1.3;
        margin-top: 2rem;
        @media (max-width: $container-md) {
          font-size: $text-sm;
        }
      }
      .buttons {
        align-items: center;
        color: white;
        display: flex;
        gap: 12px;
        margin-top: 2rem;
        .button-primary {
          
          padding: 24px 26px !important;
          @include button(primary, md);
        }
        .button-tertiary {
          align-items: center;
          display: flex;
          font-size: 12px;
          font-size: $text-lg;
          font-weight: 500;
          gap: 4px;
          padding: 12px 40px !important;
          @include button(tertiary, lg);
        }
      }
    }
    .slide {
      align-items: center;
      display: flex;
      gap: 7rem;
      margin-left: 3rem;
      margin-top: 2rem;
      position: relative;

      @media (max-width: $container-md) {
        flex-direction: column;
        gap: 3rem;
        justify-content: center;
        margin-left: 0;
        margin-top: 1rem;
        width: 100%;
      }

      .highlighte-image {
        img {
          min-width: 110%;
          @media (max-width: $container-md) {
            margin-top: 2rem;
            min-width: 100%;
            width: 100%;
          }
        }
      }
      .sliderBg {
        left: -40px;
        position: absolute;
        top: -90px;
        user-select: none;
        width: 95%;
        z-index: -15;
        @media (max-width: $container-md) {
          display: none;
        }
      }
      .subimg {
        position: absolute;
        right: 200px;
        top: -120px;
        user-select: none;
        z-index: -10;
        @media (max-width: $container-md) {
          display: none;
        }
      }
      .items {
        ul {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          @media (max-width: $container-md) {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(5, 1fr);
          }
          @media (max-width: $container-sm) {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(3, 1fr);
          }
          @media (max-width: $container-xs) {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
          }
          li {
            align-items: center;
            background-color: $white;
            border-radius: 9999px;
            color: $black;
            cursor: pointer;
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            padding: 6px 0;
            padding-left: 10px;
            padding-right: 50px;
            transition: $transition-default;
            user-select: none;

            @media (max-width: $container-md) {
              gap: 6px;
              padding: 3px 0;
              padding-left: 5px;
              padding-right: 25px;
            }

            &:hover {
              background-color: $primary-color;
              color: $primary-text-color;
              scale: 1.1;

              @media (max-width: $container-md) {
                scale: 1.05;
              }
            }
            img {
              user-select: none;
              width: 40px;
            }
          }

          .active {
            background-color: $primary-color;
            color: $primary-text-color;
            scale: 1.2 !important;

            @media (max-width: $container-md) {
              scale: 1.1 !important;
            }
          }
        }
      }
    }
  }

  .bgPattern1 {
    left: 50px;
    position: absolute;
    top: -120px;
    user-select: none;
    @media (max-width: $container-md) {
      display: none;
    }
  }

  .bgPattern2 {
    bottom: 0;
    position: absolute;
    right: 0;
    user-select: none;
    @media (max-width: $container-xs) {
      display: none;
    }
  }
}
