.catering-container {
    position: relative;
    text-align: center;
   
  }
  
  .catering-image {
    width: 100%;
    height: auto;
   
  }
  
  .catering-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .booking-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1; 
  }
  
  .booking-section h2 {
    font-size: 28px;
    color: #333;
  }
  
  .booking-section p {
    font-size: 18px;
    color: #666; 
  }
  
  .book-now-btn {
    background-color: #007bff;
    color: #fff; 
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .book-now-btn:hover {
    background-color: #0056b3;
  }
  
  .services-section {
    margin-top: 50px;
  }
  
  .services-section h2 {
    font-size: 28px;
    color: #333; 
  }
  
  .service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-card {
    width: 250px;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
  }
  
  .service-card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .service-card h3 {
    margin-top: 10px;
    font-size: 20px;
    color: #333; 
  }
  
  .service-card p {
    margin-top: 10px;
    color: #666;
  }
  