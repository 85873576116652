

.carousel .slide {
  background: rgb(240, 228, 212); /* Set the background color here */
  color: black;
  height: 100%;
}




.t-container{
    background-color: rgb(240, 228, 212)!important;
    height: 620px;
    width: 1300px;
    margin-left: 80px;
    border-radius: 30px;
}



.carousel-root {
  width: 80%; /* Adjust the width as needed */
  margin: auto;
  margin-top: 3%;
}

.myCarousel {
  background: rgb(240, 228, 212);
  margin-top: -6%;
  width: 70%; /* Adjust the width as needed */
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  
  height: auto; /* Change to auto to adapt to the content */
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
  margin-bottom: 25px; 
  
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}
.t-title{
  padding-top: 50px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #453a3a;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
  
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 664px) {
  

  .t-container{
    width: 350px;
   
   margin-left: 35px;
  }
}